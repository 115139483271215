import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

export default function Kontakt() {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    const serviceId = process.env.REACT_APP_SERVICEID_L;
    const templateId = process.env.REACT_APP_TEMPLATEID_L;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY_L;

    console.log(publicKey);
    emailjs
      .sendForm(
        serviceId, //service ID
        templateId, //template ID
        form.current,
        publicKey //public key
      )
      .then(
        (result) => {
          setLoading(false);
          setOpenModal(true);
        },
        (error) => {
          setLoading(false);
          alert("An Error occured. Your mail has not been sent.");
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <section className="container-fluid py-5" id="contact" tabIndex="0">
      <h2 className="display-2 text-center py-4">Kontakt</h2>
      <div className="container">
        <form
          ref={form}
          className="contactForm"
          aria-label="contact form to send an email"
          onSubmit={sendEmail}
        >
          <div className="mb-3 justify-content-center">
            <label htmlFor="email" className="form-label">
              Deine Email-Addresse
            </label>
            <input
              className="form-control text-light bg-black"
              type="email"
              placeholder="youremailaddress@example.com"
              name="email"
              id="email"
              required
            ></input>
          </div>
          <div>
            <label htmlFor="message" className="form-label">
              Deine Nachricht an mich
            </label>
            <textarea
              className="form-control text-light bg-black"
              name="message"
              id="message"
              rows="6"
              required
            ></textarea>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <button
              className="btn btn-outline-light p-2 m-3 w-25"
              type="submit"
            >
              Senden
            </button>
            {loading && (
              <div
                className="container spinner-border text-light"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </div>
        </form>
      </div>

      {openModal && <p className="text-center"> Danke für Deine Nachricht! </p>}
    </section>
  );
}
