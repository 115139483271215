import "../scss/navbar.css";
import BurgerMenu from "./BurgerMenu";

export default function Navbar() {
  const items = [
    { name: "Über mich", path: "#aboutme" },
    { name: "Videos", path: "#myvideos" },
    { name: "Musik", path: "#mymusic" },
    { name: "Songtexte", path: "#songtexte" },
    { name: "Blog", path: "#blog" },
    { name: "Kontakt", path: "#contact" },
  ];
  const burgermenu = true;

  return (
    <>
      <nav>
        {/* // import logo here */}
        <div className="navLogo-container">
          <img
            src="../images/Linov-Logo-2.jpg"
            className="position-relative top-0 left-0"
            alt="Logo Linov"
            style={{ width: "50px" }}
          ></img>
        </div>
        <div className="navItem-Container">
          {items &&
            items.map((element, index) => {
              return (
                <a href={element.path} className="navItem" key={index}>
                  {element.name}
                </a>
              );
            })}
        </div>
      </nav>
      {burgermenu && <BurgerMenu />}
    </>
  );
}
