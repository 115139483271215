export default function Blog() {
  return (
    <section id="blog" className="container-fluid py-5" tabIndex="0">
      <div>
        <h2 className="display-2 text-center py-4">Blog</h2>
        <div className="row justify-content-center">
          <article className="text-center align-middle col-sm-12  col-md-8 col-lg-5 pt-5">
            <h3 className="text-center p-3">Die Website ist da!</h3>
            <time datetime="10.08.2023" aria-label="10. August 2023">
              10.08.2023
            </time>
            <p className="text-center ">
              Die neue Website ist endlich online! So kann ich Euch besser
              erreichen und berichten, wenn es ein neues Lied gibt. Stay tuned!
            </p>
          </article>
          <article className="text-center align-middle col-sm-12  col-md-8 col-lg-5 pt-5">
            <h3 className="post-title text-center p-3">
              "Trotzdem" kommt bald
            </h3>
            <time datetime="11.08.2023" aria-label="11. August 2023">
              11.08.2023
            </time>
            <p className="text-center ">
              Mein neuer Song "Trotzdem" wird bald erscheinen. Derzeit drehen
              wir noch ein Video und geben dem Song den letzten Schliff - ich
              halte Euch auf dem Laufenden, wenn er released ist!
            </p>
          </article>
          <article className="text-center align-middle col-sm-12  col-md-8 col-lg-5 pt-5">
            <h3 className="post-title text-center p-3">
              Neuer Song: Star Shooting
            </h3>
            <time datetime="01.01.2024" aria-label="1. Januar 2024">
              01.01.2024
            </time>
            <p className="text-center ">
              Jo Leute! Ich wollte Euch nur ganz kurz informieren, dass ein
              neuer Song jetzt draußen ist. Er heißt "Star Shooting" von trssn!,
              der mich featured. Der Song ist ab sofort auf allen Plattformen
              erhältlich, Ihr könnt ihn
              <a
                href="https://open.spotify.com/intl-de/track/4U7SyR4YRHeoN5db6kNQXu"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "underline",
                  textUnderlineOffset: "5px",
                }}
              >
                hier auf Spotify anhören.
              </a>
            </p>
          </article>
        </div>
      </div>
      <div className="container-sm">
        <img
          id="LinovSongLaptop"
          src="/images/Linov_schreibt_Lied_am_PC.webp"
          alt="Linov schreibt einen Songtext am Laptop"
          className="img-fluid pt-5"
        ></img>
      </div>
    </section>
  );
}
