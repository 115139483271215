import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

export default function ShareButtons() {
  return (
    <div className="mb-3">
      <p className="text-center">
        <strong>Diese Seite teilen</strong>
      </p>
      <div className="d-flex justify-content-center">
        <div className="p-2">
          <EmailShareButton
            aria-label="Teilen per eMail"
            url={"https://www.linov-music.de/"}
            children=""
            subject="Schau mal die Webseite von Linov an:"
          >
            <EmailIcon size={32} round={true} iconFillColor="black" />
          </EmailShareButton>
        </div>

        <div className="p-2">
          <FacebookShareButton
            aria-label="Teilen auf Facebook"
            url={"https://www.linov-music.de/"}
            children=""
            quote="Schau mal die Webseite von Linov an:"
            hashtag="#linov"
          >
            <FacebookIcon size={32} round={true} iconFillColor="black" />
          </FacebookShareButton>
        </div>

        <div className="p-2">
          <WhatsappShareButton
            aria-label="Teilen auf Whatsapp"
            url={"https://www.linov-music.de/"}
            children=""
            title="Schau mal die Webseite von Linov an:"
            hashtag="#linov"
          >
            <WhatsappIcon size={32} round={true} iconFillColor="black" />
          </WhatsappShareButton>
        </div>

        <div className="p-2">
          <TwitterShareButton
            aria-label="Teilen auf Twitter/X"
            url={"https://www.linov-music.de/"}
            children=""
            title="Schau mal die Webseite von Linov an:"
            hashtag="#linov"
          >
            <TwitterIcon size={32} round={true} iconFillColor="black" />
          </TwitterShareButton>
        </div>
        {/* <div className="p-2">
          <a href="https://signal.me/#p/+1231232341234" target="_blank"></a>
          <BsSignal className="shareLogo" size={32} />
        </div> */}
      </div>
    </div>
  );
}
