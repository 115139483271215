import { useState } from "react";

export default function BurgerMenu() {
  const [burgermenuOpen, setBurgermenuOpen] = useState(false);

  const burgerMenuItems = [
    { name: "Über mich", path: "#aboutme" },
    { name: "Videos", path: "#myvideos" },
    { name: "Musik", path: "#mymusic" },
    { name: "Songtexte", path: "#songtexte" },
    { name: "Blog", path: "#blog" },
    { name: "Kontakt", path: "#contact" },
  ];

  const toggleMenu = () => {
    setBurgermenuOpen(!burgermenuOpen);
    console.log("menu open");
  };

  return (
    <div>
      <div>
        <span
          className={
            burgermenuOpen
              ? "burgermenu-bars-container openedmenu"
              : "burgermenu-bars-container"
          }
        >
          <button
            className="burgermenu-button"
            aria-label="open menu"
            onClick={() => toggleMenu()}
          ></button>
          <span
            className={
              burgermenuOpen ? "burgermenu-bars cross-1" : "burgermenu-bars"
            }
            style={{ top: "0px" }}
          ></span>
          <span
            className={
              burgermenuOpen ? "burgermenu-bars cross-2" : "burgermenu-bars"
            }
            style={{ top: "40%" }}
          ></span>
          <span
            className={
              burgermenuOpen ? "burgermenu-bars cross-3" : "burgermenu-bars"
            }
            style={{ top: "80%" }}
          ></span>
          {/* {burgermenuOpen ? (
            <p className="instr openedmenu">Schliessen</p>
          ) : (
            <p className="instr">öffnen</p>
          )} */}
        </span>
      </div>
      <menu className={burgermenuOpen ? "burgermenu opened" : "burgermenu"}>
        {burgerMenuItems &&
          burgerMenuItems.map((burgerItem, index) => {
            return (
              <li className="burgermenu-listItems" key={index}>
                <a href={burgerItem.path}>{burgerItem.name}</a>
              </li>
            );
          })}
      </menu>
      {burgermenuOpen && <div className="overlay"></div>}
    </div>
  );
}
