import ShareButtons from "./ShareButtons";
import { useConsent, ConsentContext } from "react-hook-consent";

export default function Footer() {
  const { toggleBanner } = useConsent(ConsentContext);
  return (
    <>
      <footer className="container-fluid bg-black align-middle pt-5">
        <div className="d-flex flex-column flex-sm-row justify-content-evenly">
          <div className="pb-4">
            <ShareButtons />
          </div>
          <div className="pb-4 d-flex flex-column align-items-start justify-content-start">
            <ul tabIndex="0">
              <strong>Credits</strong>
              <li className="mt-3">
                <p>Musik und Lyrics: Linov</p>
              </li>
              <li>
                <p>
                  Videos und Musikproduktion:
                  <a
                    href="https://dervj.de/"
                    target="_blank"
                    rel="noreferrer"
                    className="links"
                  >
                    www.derVJ.de
                  </a>
                </p>
              </li>
              <li>
                {" "}
                <p>
                  Webseite:
                  <a
                    href="https://www.schwachmeyer.dev/"
                    target="_blank"
                    rel="noreferrer"
                    className="links"
                  >
                    www.schwachmeyer.dev
                  </a>
                </p>
              </li>
              <li>
                <p>
                  Titelfoto von{" "}
                  <a
                    href="https://unsplash.com/de/fotos/graue-wolken-h-rP5KSC2W0"
                    className="links"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Michael & Diane Weidner
                  </a>
                  auf Unsplash
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="d-flex align-items-end justify-content-start p-3">
          <div className="text-center">
            <img
              src="../images/Linov-Logo-2.jpg"
              className="bg-black p-2"
              style={{ width: "70px" }}
              alt="Logo Linov"
            ></img>
            <p style={{ fontSize: "0.7em" }}>&copy; 2023 by Linov</p>
          </div>
          <button
            className="btn p-2 mb-2 w-25 cookieButton"
            onClick={() => toggleBanner()}
          >
            Cookie-Einstellungen
          </button>
        </div>
      </footer>
      <div className="spacer"></div>
    </>
  );
}
