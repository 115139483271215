import { useConsent, ConsentContext } from "react-hook-consent";

export default function MyMusic() {
  const { hasConsent, toggleBanner } = useConsent(ConsentContext);
  const spotifyConsent = "spotifyConsent";
  return (
    <section id="mymusic" className="container-fluid py-5" tabIndex="0">
      <div>
        <h2 className="display-2 text-center py-4">Meine Musik</h2>
        <div className="row justify-content-center d-flex gap-4">
          <div className="col-12 col-md-7 col-lg-5 mx-1">
            <p className="text-light bg-black container-sm" tabIndex="0">
              Musik bedeutet für mich vor allem: Emotion. Von Trauer, damit man
              sich mal so richtig ausheulen kann, bis hin zur Freude, weil man
              verliebt ist.
              <br></br>Ich werde meinen Weg mit der melancholischen Musik
              weitergehen. Es passt zu mir und fühlt sich richtig an. Ihr findet
              meine Lieder auf verschiedenen Kanälen, zum Beispiel:
            </p>
            <div className="d-flex justify-content-center">
              <a
                href="https://www.youtube.com/@Linovmusik/videos"
                target="blank"
                className="p-2"
                aria-label="Linovs YouTube Channel"
              >
                <img
                  className="logo"
                  src="images/YT-small.png"
                  alt="YouTube logo"
                ></img>
              </a>
              <a
                href="https://music.apple.com/us/artist/linov/1590717587"
                target="blank"
                className="p-2"
              >
                <img
                  className="logo"
                  src="images/iTunes-small.png"
                  alt="iTunes logo"
                ></img>
              </a>
              <a
                href="https://open.spotify.com/intl-de/artist/3aAVb6d1vznlMyCbunIMyO"
                target="blank"
                className="p-2"
              >
                <img
                  className="logo"
                  src="images/Spotify-small.png"
                  alt="Spotify logo"
                ></img>
              </a>
              <a
                href="https://www.amazon.de/music/player/artists/B09JMRGP2C/linov"
                target="blank"
                className="p-2"
              >
                <img
                  className="logo"
                  src="images/Amazon-small.png"
                  alt="Amazon logo"
                ></img>
              </a>
            </div>
          </div>
          {hasConsent(spotifyConsent) ? (
            <iframe
              className="col-12 col-md-4 col-lg-5 mx-1"
              src="https://open.spotify.com/embed/artist/3aAVb6d1vznlMyCbunIMyO?utm_source=generator&theme=0"
              width="100%"
              height="352"
              allowFullScreen=""
              title="Linovs music on Spotify"
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
              tabIndex="0"
            ></iframe>
          ) : (
            <div
              className="imageLinkContainer col-12 col-md-6 col-lg-5 mx-1 d-flex flex-column justify-content-center align-items-center"
              tabIndex="0"
            >
              <a
                href="https://open.spotify.com/intl-de/artist/3aAVb6d1vznlMyCbunIMyO"
                target="_blank"
                className="btn btn-secondary p-2 m-3 w-25 link2Spotify text-center align-center"
              >
                Gehe zu Spotify
              </a>

              <img
                src="images/Spotify-Playlist.webp"
                alt="Spotify Playlist"
                width="100%"
                height="100%"
                className="imageLink"
              ></img>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
