export default function AboutMe() {
  return (
    <section id="aboutme" className="container-fluid py-5" tabIndex="0">
      <div>
        <h2 id="h2" className="display-2 text-center py-4">
          Über mich
        </h2>
        <div className="row justify-content-center d-flex gap-4">
          <div className="col-12 col-md-7 col-lg-5 mx-1">
            <img
              src="/images/Linov_am_Klavier.webp"
              alt="Linov sitzt am Klavier"
              className="mt-1 w-100"
              id="aboutMePhoto"
            ></img>
          </div>
          <div className="col-12 col-md-7 col-lg-5 mx-1">
            <p className="text-light m-0">
              Hallo, ich bin Linov (gesprochen: Lino), geboren 2010 in Berlin.
              Für Musik begeistere ich mich schon immer. Ich spiele
              unterschiedliche Instrumente wie Gitarre, Klavier, Schlagzeug,
              Bass und Geige. Seit 2022 veröffentliche ich hier regelmäßig neue
              Songs. Warum schreibe ich? Weil eine Person in mein Leben getreten
              ist, die mir viel bedeutet und mich sehr beschäftigt.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
