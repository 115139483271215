import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/style.scss";
import React, { useEffect } from "react";
import LandingPage from "./components/LandingPage";
import AboutMe from "./components/AboutMe";
import MyVideos from "./components/MyVideos";
import Blog from "./components/Blog";
import Navbar2 from "./components/Navbar2";
import Footer from "./components/Footer";
import MyMusic from "./components/MyMusic";
import Songtexte from "./components/Songtexte";
import Kontakt from "./components/Kontakt";
import { useConsent } from "react-hook-consent";

function App() {
  const { toggleBanner } = useConsent();

  useEffect(() => {
    toggleBanner();
  }, []);

  return (
    <div className="App">
      <Navbar2 />
      <LandingPage />
      <AboutMe />
      <MyVideos />
      <MyMusic />
      <Songtexte />
      <Blog />
      <Kontakt />
      <Footer />
    </div>
  );
}

export default App;
