import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "react-hook-consent/dist/styles/style.css";
import App from "./App";
import { ConsentBanner, ConsentProvider } from "react-hook-consent";
import reportWebVitals from "./reportWebVitals";

const ConsentOptions = {
  services: [
    {
      id: "technical",
      name: "Technical",
      description:
        "Wir nutzen eine externe Library zum Styling unserer Webseite. Ohne diese Funktion werden die Inhalte nicht richtig angezeigt.",
      scripts: [
        {
          id: "external-script",
          src: "https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js",
        },
      ],
      mandatory: true,
    },
    {
      id: "youTubeConsent",
      name: "YouTube",
      description:
        "Wir haben YouTube videos eingebettet, um die Musikvideos zu zeigen. YouTube platziert Cookies in Deinem Browser. Wenn Du dies nicht wünscht, werden Dir die Musikvideos nicht angezeigt.",
      scripts: [
        {
          id: "youtube-script",
          src: "https://www.youtube.com/iframe_api",
        },
        {
          id: "Linov-trotzdem",
          src: "https://www.youtube.com/embed/eaGwGvntWzc",
        },
        {
          id: "Linov-ichbinfuerdichda",
          src: "https://www.youtube.com/embed/mVtdvEB5Xig",
        },
        {
          id: "trssnlinov-starshooting",
          src: "https://www.youtube.com/embed/5ugSgFb5lyY?si=QEjsZ76nhWiNIDl7",
        },
      ],
    },
    {
      id: "spotifyConsent",
      name: "Spotify",
      description:
        "Wir haben eine Funktion von Spotify eingebettet, um Songs abspielen zu können. Spotify platziert Cookies in Deinem Browser. Wenn Du dies nicht möchtest, kannst Du die Lieder nicht bei Spotify anhören.",
      scripts: [
        {
          id: "spotify-script",
          src: "https://open.spotify.com/embed/iframe-api/v1",
        },
        {
          id: "linov-spotify",
          src: "https://open.spotify.com/embed/artist/3aAVb6d1vznlMyCbunIMyO?utm_source=generator&theme=0",
        },
      ],
    },
  ],
  theme: "dark",
};

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConsentProvider options={ConsentOptions}>
      <App />
      <ConsentBanner
        settings={{
          hidden: false,
          label: "Einzeln festlegen",
          modal: { title: "Modal title" },
        }}
        decline={{ show: true, label: "Alle ablehnen" }}
        approve={{ label: "Erlauben" }}
      >
        <>
          Wir verwenden Cookies, um Dir Musik und Videos anzuzeigen. Du kannst
          entscheiden, welchen Funktionen Du zustimmen möchtest.
        </>
      </ConsentBanner>
    </ConsentProvider>
  </React.StrictMode>,
  root
);

reportWebVitals(console.log);

//
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
