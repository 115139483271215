import React from "react";
import { useConsent, ConsentContext } from "react-hook-consent";

export default function MyVideos() {
  const { hasConsent, toggleBanner } = useConsent(ConsentContext);
  const youTubeConsent = "youTubeConsent";

  return (
    <section id="myvideos" className="container-fluid py-5" tabIndex="0">
      {" "}
      <div>
        <h2 className="display-2 text-center py-4">Videos</h2>
        <div className="row justify-content-center display-flex gap-4">
          {hasConsent(youTubeConsent) ? (
            <>
              {" "}
              <div className="col-12 col-md-6 col-lg-5 mx-1">
                <div className="ratio ratio-16x9 videos">
                  ::before
                  <iframe
                    aria-label="Linovs Video zum Lied Trotzdem"
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/eaGwGvntWzc"
                    title="Linov - Trotzdem"
                    allow="accelerometer; autoplay; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-5 mx-1">
                <div className="ratio ratio-16x9 videos">
                  ::before
                  <iframe
                    aria-label="Linovs Video zum Lied Ich bin für Dich da"
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/mVtdvEB5Xig"
                    title="Linov - Ich bin für Dich da"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-5 mx-1">
                <div className="ratio ratio-16x9 videos">
                  ::before
                  <iframe
                    aria-label="Musikvideo Trssn! feat. Linov - Star Shooting"
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/5ugSgFb5lyY?si=QEjsZ76nhWiNIDl7"
                    title="Trssn feat. Linov - Star Shooting"
                    allow="accelerometer; autoplay; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="imageLinkContainer col-12 col-md-6 col-lg-5 mx-1 d-flex flex-column justify-content-center align-items-center">
                <a
                  href="https://www.youtube.com/watch?v=eaGwGvntWzc"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-secondary p-2 m-3 w-25 link2Spotify text-center align-center"
                  tabindex="0"
                >
                  Gehe zu YouTube
                </a>

                <img
                  src="images/Linov-Trotzdem.webp"
                  alt="Video: Trotzdem"
                  width="100%"
                  height="100%"
                  className="imageLink"
                ></img>
              </div>
              <div className="imageLinkContainer col-12 col-md-6 col-lg-5 mx-1 d-flex flex-column justify-content-center align-items-center">
                <a
                  href="https://www.youtube.com/watch?v=mVtdvEB5Xig"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-secondary p-2 m-3 w-25 link2Spotify text-center align-center"
                  tabindex="0"
                >
                  Gehe zu YouTube
                </a>

                <img
                  src="images/Linov-IchBinFürDichDa.webp"
                  alt="Video: Ich bin für Dich da"
                  width="100%"
                  height="100%"
                  className="imageLink"
                ></img>
              </div>

              <div className="imageLinkContainer col-12 col-md-6 col-lg-5 mx-1 d-flex flex-column justify-content-center align-items-center">
                <a
                  href="https://www.youtube.com/watch?v=5ugSgFb5lyY"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-secondary p-2 m-3 w-25 link2Spotify text-center align-center"
                  tabindex="0"
                >
                  Gehe zu YouTube
                </a>

                <img
                  src="images/trssn-starshooting.webp"
                  alt="Video: Star Shooting"
                  width="100%"
                  height="100%"
                  className="imageLink"
                ></img>
              </div>
            </>
            // <div className="col-12 col-md-6 col-lg-5 mx-1 d-flex flex-column justify-content-center align-items-center">
            //   <p className="text-center">
            //     Diese Videos werden Dir nicht angezeigt, weil Du gegen die
            //     Verwendung von YouTube widersprochen hast.
            //   </p>
            //   <button
            //     className="btn btn-outline-light p-2 m-3 w-25"
            //     onClick={() => toggleBanner()}
            //   >
            //     YouTube Videos erlauben
            //   </button>
            // </div>
          )}
        </div>
      </div>
    </section>
  );
}
