export default function Songtexte() {
  return (
    <section id="songtexte" className="container-fluid py-5" tabIndex="0">
      <div>
        <h2 className="display-2 text-center py-4">Songtexte</h2>
        {/* Accordion */}
        <div className="row justify-content-center">
          <div
            className="accordion accordion-flush col-sm-12 col-md-10 col-lg-6"
            id="accordionFlushExample"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFour">
                <button
                  className="accordion-button collapsed text-light bg-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  Bitte geh nicht
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div
                  className="accordion-body text-light bg-black"
                  tabIndex="0"
                >
                  Weißt du wie sehr ich dich vermisse<br></br>
                  Mein Herz hat überall nur noch Risse<br></br>
                  Ich kann kein Auge zudrücken <br></br>
                  Wenn wir uns wieder mal gestritten haben<br></br>
                  Und was weiß ich <br></br>
                  Vielleicht nehm ich dich ja nie in den Arm<br></br> <br></br>
                  Ich würd mit dir alles teilen <br></br>
                  Ich würde jeden Tag weinen<br></br>
                  Nur damit du ewig lachst<br></br>
                  Jede Nacht<br></br>
                  Ist es so dass ich dich seh <br></br>
                  Und das tut dann lange<br></br>
                  Bitte geh nicht<br></br> <br></br>
                  Ich hoff das du mich vermisst <br></br>
                  Ich hoff das du mich nicht vergisst<br></br>
                  Es ist einfach dich zu lieben<br></br>
                  Schwer dich zu hassen<br></br>
                  Einfach einen Korb zu kriegen<br></br>
                  Schwer von dir zu lassen<br></br> <br></br>
                  Ich würd mit dir alles teilen <br></br>
                  Ich würde jeden Tag weinen<br></br>
                  Nur damit du ewig lachst<br></br>
                  Jede Nacht<br></br>
                  Ist es so das ich dich seh <br></br>
                  und das tut dann lange<br></br>
                  Bitte geh nicht<br></br> <br></br>
                  Und bitte schrei mich jetzt nicht an<br></br>
                  Wir solln nicht nochmal von vorn anfangen <br></br>
                  Ich bin in einen Traum von dir gefangen <br></br>
                  Ich hab versucht dort heraus zu gelangen<br></br>
                  Geht nicht<br></br> <br></br>
                  Ich würd mit dir alles teilen <br></br>
                  Ich würde jeden tag weinen<br></br>
                  Nur damit du ewig lachst<br></br>
                  Jede Nacht<br></br>
                  Ist es so das ich dich seh <br></br>
                  Und das tut dann lange<br></br>
                  Bitte geh nicht<br></br>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed text-light bg-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Ich bin für Dich da
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div
                  className="accordion-body text-light bg-black"
                  tabIndex="0"
                >
                  Es tut mir leid <br></br>Da zu sein <br></br>Jeden Tag{" "}
                  <br></br>
                  Bin ich für dich da <br></br>Ich bin da für dich<br></br> wenn
                  du mich brauchst <br></br>Ich hatte Hoffnung bis du es
                  versaust <br></br>
                  <br></br>Doch ich liebe dich <br></br>Und ich liebe dich{" "}
                  <br></br>
                  <br></br>So viel <br></br>Ich gebe dir <br></br>Hoff, dass du
                  zurück gibst <br></br>Ich nehm so viel Rücksicht <br></br>Ich
                  würd dir alles geben <br></br>Doch du bist nur da um zu nehmen{" "}
                  <br></br>
                  <br></br>Doch ich liebe dich <br></br>Und ich liebe dich{" "}
                  <br></br>
                  <br></br>Also nimm meine Hand <br></br>Und wir gehen den Weg
                  zusammen<br></br> Ich bin da für dich
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed text-light bg-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  She
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse text-light bg-black"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div
                  className="accordion-body text-light bg-black"
                  tabIndex="0"
                >
                  I’m glad to see you<br></br>I don’t wanna leave you<br></br>
                  you are my crush<br></br>
                  and I dont give up<br></br>
                  you are my biggest love and I<br></br>
                  love you so much<br></br>I love your face, you are so great
                  <br></br>
                  <br></br>
                  I’m made for you<br></br>
                  it’s so true<br></br>I see your eyes<br></br>
                  and I want to try <br></br>
                  to get to you<br></br>
                  ohhhh<br></br>
                  you have so lovely eyes<br></br>I want to try to get to you
                  <br></br>
                  <br></br>
                  I’m glad to see you<br></br>I dont wanna leave you<br></br>
                  you are my crush<br></br>
                  and I don’t give up<br></br>
                  you are my biggest love and I <br></br>
                  love you so much<br></br>I love your face, you are so great
                  <br></br>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree">
                <button
                  className="accordion-button collapsed text-light bg-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Trotzdem
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div
                  className="accordion-body text-light bg-black"
                  tabIndex="0"
                >
                  Ich hass mich dafür <br></br>
                  dass ich’s nicht tun kann<br></br>
                  zu sagen was ich fühl <br></br>
                  und was ich such, Mann<br></br>
                  Du bist das reine Gute,<br></br>
                  ich bin so dumm, <br></br>
                  dass ich es nicht mal versuche.<br></br>
                  Mein Herz hat love scars <br></br>
                  wie von hier bis zum Mars.<br></br>
                  Es ist nicht deine Schuld<br></br>
                  Ich hab einfach keine Geduld.<br></br>
                  <br></br>
                  Ich liebe dich<br></br>
                  Aber ohne dich geht’s nicht<br></br>
                  Ich bin traurig<br></br>
                  Aber letztendlich bringt das ja auch nichts<br></br>
                  <br></br>
                  Aber bitte lass uns nicht streiten<br></br>
                  Wer wen mehr liebt<br></br>
                  Es ist nicht wie in früheren Zeiten<br></br>
                  So war’s noch nie<br></br>
                  Ich geh kaputt während du glücklich bist <br></br>
                  Und ich werd andauernd nur noch gedisst<br></br>
                  <br></br>
                  Wenn’s für dich nur so ist<br></br>
                  Wenn’s dir egal ist<br></br>
                  <br></br>
                  Aber ich werd dich trotzdem lieben <br></br>
                  Und wie du siehst, bin ich geblieben<br></br>
                  Ich weiß es ist manchmal schwer für mich <br></br>
                  Aber ich meins ernst, ich bin da für dich<br></br>
                  <br></br>
                  Ich geh kaputt, während du glücklich bist<br></br>
                  Und ich werd andauernd nur noch gedisst<br></br>
                  <br></br>
                  Wenn’s für dich nur so ist<br></br>
                  Wenn’s dir egal ist<br></br>
                  <br></br>
                  Aber ich werd dich trotzdem lieben <br></br>
                  Und wie du siehst, bin ich geblieben<br></br>
                  Ich weiß es ist manchmal schwer für mich<br></br>
                  Aber ich meins ernst ich bin da für dich<br></br>
                  Aber ich werd dich trotzdem lieben<br></br>
                  Und wie du siehst, bin ich geblieben<br></br>
                  Ich weiß es ist manchmal schwer für mich<br></br>
                  Aber ich meins ernst ich bin da für dich<br></br>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFive">
                <button
                  className="accordion-button collapsed text-light bg-black"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  Warum
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample"
              >
                <div
                  className="accordion-body text-light bg-black"
                  tabIndex="0"
                >
                  Warum muss ich jedem wehtun?<br></br>
                  Warum kann die Liebe nicht einmal ruhen?<br></br>
                  Warum muss ich jedem wehtun?<br></br>
                  <br></br>
                  Niemand sagt alles gut <br></br>
                  Ich seh in den Blicken<br></br>
                  Lass mich in Ruh<br></br>
                  Wenn ich schon wieder einen Fehler gemacht hab<br></br>
                  Ist es so das alle mich anstarren<br></br>
                  Ich kann nichts dafür<br></br>
                  Ihr schiebt mich wieder vor die Tür<br></br>
                  Auch wenn es so aussieht als wäre nichts<br></br>
                  Ist da was doch ich fühle nichts<br></br>
                  <br></br>
                  Warum muss ich jedem wehtun?<br></br>
                  Warum kann die Liebe nicht einmal ruhen?<br></br>
                  Warum muss das so wehtun?<br></br>
                  <br></br>
                  Und sie gibt mir Schmerz tief ins Herz <br></br>
                  Lieb sie so sehr sehr<br></br>
                  Sie schreibt ich solle aufhören <br></br>
                  Es würde mich auch stören <br></br>
                  Immer die selben Nachrichten <br></br>
                  Aber was ist wenn ich nicht aufhören kann<br></br>
                  Wenn nichts hilft ausser lieben dann<br></br>
                  Geht alles unter was mal da war<br></br>
                  Hass und Schmerz <br></br>
                  Wieder da<br></br>
                  Ich merk was sie tut ich weiß was in ihr geschrieben steht
                  <br></br>
                  Bitte nur noch ein Versuch <br></br>
                  Doch es ist zu spät<br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
