export default function LandingPage() {
  return (
    <div className="landingPageContainer">
      <div className="startTextContainer">
        <h1 id="title">Linov</h1>
      </div>
      <div id="parallax" className="landingPageImage"></div>
    </div>
  );
}
